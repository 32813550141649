import { useState, useEffect, memo } from 'react';
import Service from "../services/service";
import ModalVideo from "./modalVideo";
import Reports from "./reports";

/* reports */
const Index = (props) => {

  const month = new Date().getMonth()+1;
  const year = new Date().getFullYear();
  const monthName = new Date().toLocaleDateString('pt-BR', { month: 'short'}).toUpperCase().replace(".","")

  return (    <div className="py-4 reports">
                <div className="text-center">
                  <h3 className="barlow fw-bold">Real time</h3>
                  <p className="text-muted m-0">acompanhe as estatísticas da Odete</p>
                  <p className="text-center fw-bold">{monthName}/{year} a cada...</p>        
                </div>
                <div className="d-flex gap-2 justify-content-sm-center table-responsive">
                    <Reports model='colaboradors' month={month} year={year} title='um novo usuário' />
                    <Reports model='publishes' month={month} year={year} title='uma nova oferta' />
                    <Reports model='findregisters' month={month} year={year} title='uma nova pesquisa' />
                    <Reports model='solicitacoes' month={month} year={year} title='um pedido de telefone' />
                </div>
              </div>
        )
}

export default memo(Index);