import React, { useEffect, memo, useState, useId } from "react";
import Image from "./image";
import Service from "../services/service";
import Link from "../node_modules/next/link";

const Index = (props) => {

  const [page, setPage] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState(null); 
  const id = useId();

  useEffect(() => {

    const intersectionObserver = new IntersectionObserver(entries => {

      if (entries.some(entry => entry.isIntersecting)) {
        setPage((prevPage) => true);
      }
    })
    if(document.getElementById(`${id}`)){
        intersectionObserver.observe(document.getElementById(`${id}`));
    }
    return () => intersectionObserver.disconnect();
  }, []);

  useEffect(() => {
    
    if(page && !loaded){

        Service.get(`${Service.api}/reports/reportsByAvgUfCidade`)
        .then(resp => {
          setData(resp);
          setLoaded(true);
        })
        .catch((e) => console.log('erro ao consultar api ultima dica cadastrada -> ', e));
    }

    return () => {}
  }, [page])

  return (
      <div className="py-4 bg-light border-top">
        <div className="container py-4">
        <div className="text-center">
          <h3 className="barlow fw-bold">Odete está presente nos 27 estados do Brasil</h3>
          <p>onde você precisa de uma diarista?</p>
        </div>
        <div id={id} className="m-auto row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 g-5">
        {
                      data?.map((item, index) => {
                            return <div className="card-animated" key={`estado-${index}`}>
                                <Link href={`/diaristas/${item._id.toLowerCase()}`}>
                                    <div className="d-flex align-items-center">
                                      <Image width="40" height="28" className="border-dark border me-2" src={`/assets/images/bandeiras/${item._id.toLowerCase()}.png`} alt={item._id} />
                                      <h4>{item._id}</h4>
                                    </div>
                                    <h5 className="d-block fs-6"><span className="badge bg-primary">{item.totalDiaristas}</span> diaristas em {item._id}</h5>
                                </Link>
                            </div>
                      })
        }
        </div>

        </div>
      </div>
  );
};

export default memo(Index);
