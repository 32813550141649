import { useState, useEffect, memo } from 'react';
import Service from "../services/service";
import ModalVideo from "./modalVideo";

/* reports */
const Index = (props) => {

  const [data, setData] = useState(null); 
  const [page, setPage] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {

    const intersectionObserver = new IntersectionObserver(entries => {

      if (entries.some(entry => entry.isIntersecting)) {
        setPage((prevPage) => true);
      }
    })
    const element = document.querySelector(`.reports`);
    intersectionObserver.observe(element);  
    
    return () => intersectionObserver.disconnect();
  }, []);

  useEffect(() => {

    if(page && !loaded){
      fetch(`${Service.api}/reports/usuarios-minuto?model=${props.model}&month=${props.month}&year=${props.year}`)
      .then(resp => resp.json())
      .then(resp => {
        setData(resp);
        setLoaded(true);
      })
      .catch((e) => console.log('erro ao consultar api reports'));
    }


    return () => {}
  }, [page]);

  return (
                <div className={`${!data ? 'placeholder-wave': ''} p-3 col text-center`}>
                            <h4 className={`${!data ? 'placeholder rounded-pill' : ''} lh display-6 m-0 text-nowrap w-100`}>{data?.time || '...'}</h4>
                            <small className={`${!data ? 'placeholder rounded-pill' : ''} m-0 barlow text-muted text-nowrap w-100`}>{data ? props?.title : '...'}</small>
                </div>
          
        )
}

export default memo(Index);