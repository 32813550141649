import { useState, memo } from 'react';
import Router from "next/router";

import InputMask from 'react-input-mask';
import swal from '@sweetalert/with-react';
import slugify from 'react-slugify';

import Service from "../services/service";
import { logEvent } from '../utils/analytics'
//import Image from "./image";
import Image from "next/image";

import { connect } from 'react-redux';
import actions from '../redux/actions';

//import ReCAPTCHA from "react-google-recaptcha";

/* ultima dica cadastrada pela diarista */
const SearchHome = (props) => {

  //let autoComplete;
  //const autoCompleteRef = useRef(null);
  //const recaptchaRef = useRef();

  const [query, setQuery] = useState("");
  const [searching, setSearching] = useState(false);
  const [searchingLocation, setSearchingLocation] = useState(false);

  // ******************************************************************
  // Esse metodo consulta o cep pegando a geolocalizacao do usuario
  // ******************************************************************
  const findNearest = async (event) => {

      setSearchingLocation(true);

      logEvent('SEARCH', 'FIND NEAREST');

      let resp = await Service.getMarker();

      if(!resp){
        swal('', 'Não foi possível obter sua localização', 'error');
        setSearchingLocation(false);
        return false;
      }

      let cep = Service.parseMarkerAddress(resp[0]).cep;
      setQuery(cep);
      findQuery(event, cep);
      setSearchingLocation(false);
  }

  // useEffect(() => {

  //     Service.getCompaniesbyGoogle().then(google => {

  //       var mytext = document.getElementById("place");

  //       mytext.addEventListener('keypress', function (evt) {
  //           if (evt.keyCode === 13) {
  //               evt.preventDefault();
  //               return false;
  //           }
  //       });

  //       autoComplete = new google.places.Autocomplete(autoCompleteRef.current, {fields: ["formatted_address", "address_components", "geometry"]});
  //       autoComplete.addListener("place_changed", () =>
  //         handlePlaceSelect(setQuery)
  //       );
  //     })
  // }, []);

  // async function handlePlaceSelect(updateQuery) {
  //   const addressObject = autoComplete.getPlace();
  //   const query = addressObject.address_components;
  //   if(!query){
  //     swal('', 'selecione um item da lista', 'info').then(() => {
  //       document.getElementById('place').focus();
  //     });
  //     return false;
  //   }

  //   findQuery(addressObject);
  // }

  const handleQuery = (event) => {

    var value = (event.target.value)
    setQuery(value);

    if(value.replace(/\D/g, "").length === 8){
      findQuery(event, value);      
    }
  }

  const findQuery = async (event, value) => {

    event?.preventDefault();

    var cep = value || query;
    cep = cep.replace(/\D/g, "");

    if (!cep) {
      //swal('', 'Digite um endereço válido ou navegue pelos estados', 'info');
      return false;
    }

    if (searching) {
      swal('', 'Processando a pesquisa pelo CEP...', 'info');
      return false;
    }
    
    if (!cep) {      
      return false;
    }

    if(cep.length !== 8){
      swal('Ixi!', 'Digite um cep válido', 'error').then(() => {
        document.getElementById('place').focus();
      });
      return false;
    }

    // const getValueRecaptcha = recaptchaRef?.current?.getValue();
    // if(getValueRecaptcha){
    //   recaptchaRef.current.reset();
    // }

    logEvent('SEARCH', 'FIND MAKER');

    setSearching(true)
    // const recaptcha = await recaptchaRef.current.executeAsync();

    Service.googleMaps(cep)
    .then(address => {

        if (!address) {
          swal('', 'Endereço não encontrado', 'warning');
          setSearching(false);
          return false;
        }

        if(address.geolocation){
          address.latitude = address?.geolocation?.latitude
          address.longitude = address?.geolocation?.longitude
        }

        var region = address?.uf;
        var cidade = slugify(address?.cidade).toLowerCase();
        Router.push({ pathname: `/diaristas/${region}/${cidade}`, query: address})

        return;

        var filters = {
          latitude: address.geolocation.latitude,
          longitude: address.geolocation.longitude,
          range: '50',
          cep: address.cep || 'sem cep',
          //recaptcha: recaptcha,
          sortBy: 'distance',
          filterBy: '',
          searchBy: ''
        };

        Service.getColaboradores(filters)
        .then(resp => {

          let colaboradores = resp.result;

          if(colaboradores.length === 0){
            swal('', 'nenhuma odete encontrado para este cep', 'warning');
            setSearching(false);
            return false;
          }

          /*******************************************************************/
          /* se chegar aqui eh que deu tudo certo e foi encontrado diaristas */
          /*******************************************************************/
          let mediaValores = colaboradores.map(item => item.valor).reduce((a, b) => a + b, 0) / colaboradores.length;

          var colChecked = colaboradores.map(item => {
            item.checkedCotacao = true;
            return item;
          });

          let state = {
            filters: filters,
            endereco: address.endereco,
            data: colChecked,
            copyData: colChecked,
            mediaValores: mediaValores,
            pesquisado: true
          }

          /*******************************************************************/
          // atualiza os dados no redux
          /*******************************************************************/
          props.findMaker(state).then(resp => {
            setSearching(false);

            /*******************************************************************/
            /* faz a pergunta pro camarada, se ele quer fazer o disparo */
            /*******************************************************************/
            // swal({
            //   title: 'Publicar Oferta!',
            //   content: <div><p className="text-center">Que tal se todas essas diaristas recebessem sua oferta e entrassem em contato diretamente com você!</p><p className="fw-bold">Gostaria de usar a funcionalidade "Publicar Oferta"?</p></div>,
            //   buttons: {
            //     cancel: "Não, Obrigado!",
            //     confirm: "Bora lá!"
            //   },
            // }).then(resp => {
            //   if(resp){
            //     //handleToggle('step', 'primeiro');
            //   }
            // });
          });

          props.publish(colChecked);

          Service.registerFind({...address, result: colaboradores.length});
        })
        .catch(e => {
          swal('', 'Desculpa, pode tentar novamente por favor', 'error');
          setSearching(false);
        })
    
    })
    .catch(e => {

      swal({content: <div><h3 className="barlow fw-bold">O google não encontrou :(</h3><p className="text-center">Ops!, usamos o Google para localizar o seu endereço e nesse caso ele não conseguiu encontrar o endereço pesquisado.</p><p className="text-muted">tente outro por favor</p></div>, icon: 'info'}).then(() => {
        setQuery(query => '');
        document.getElementById('place').value = '';
        document.getElementById('place').focus();
        setSearching(false);
      });
    })
  }

  const [imagemNumber, setImagem] = "7"; //useState(Math.floor(Math.random() * 8));

  return (<>
            <form name="form" action="/" method="GET" target="_top" onSubmit={findQuery} className="form-search text-center m-auto py-4" data-component="searchHome">
              <fieldset disabled={searching}>
                <legend className="form-text h5 m-0"></legend>
                <div className="form-group mb-0 position-relative group">
                  <label htmlFor="place" className="form-label position-relative" style={{marginBottom: '-21px'}}>
                    <Image loading="eager" src={`/assets/images/search/odete-0${imagemNumber}.png`} width="281" height="150" alt="Digite o CEP de sua região!" />
                  </label>
                  <div className="d-flex bg-white rounded-pill p-1 gap-1 border border-2 border-secondary">

                                    <InputMask
                                      type="tel"
                                      id="place"
                                      mask="99999-999"
                                      placeholder="18800-000"
                                      name="place"
                                      x-webkit-speech="true"
                                      required={true}
                                      className="form-control rounded-pill border-0 form-control-lg"
                                      value={query}
                                      onChange={handleQuery}
                                    />


                    <button type="submit" className="btn btn-dark rounded-pill" name="pesquisar">
                      {!searching ? 'Buscar' : "..."}
                    </button>
                  </div>
                  {props?.data?.location?.city}
                  <button type="button" disabled={searchingLocation || searching} onClick={findNearest} className="btn m-auto d-flex align-items-center justify-content-center btn-geolocation btn-sm" name="geolocation">
                    <img className="search-pin" src="/assets/images/icons/pin.png" width="12" height="16" alt="Mostrar as diaristas mais próximas de mim" /> <span className="ms-1">usar minha localização atual</span>
                  </button>
                </div>
              </fieldset>
            </form>
          </>
        )
}

export default connect(state => state, actions)(memo(SearchHome));